﻿<template>
    <div >
        <button v-if="isSmallScreen() && isMobile()" @click="goTo('calendar_div')" type="button" class="hover:no-underline rounded-full my-2 my-2 mx-0 md:mx-2 px-3 py-1 text-sm button button-bluelight ">
            <svg class="h-5 w-5 mr-1 inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                <path fill="currentColor" d="M64 191L98 157 262 320 426 157 460 191 262 387 64 191Z" class=""></path>
            </svg>
            Jump to Dates
        </button>
        <tour_heading  :product=product></tour_heading>

        <div class="xl:flex flex-col lg:flex-row mt-8 md:mt-12">
            <div class="left-col w-full xl:w-7/12 xl:w-6/12 xl:w-6/12 ">
                <calendar id="calendar_div" :trip_index=trip_index :product=product :tour_ids="tour_ids" :selected_tour_id="selected_tour_id" @monthChange="onMonthChange" @dateChange="onDateChange" ref="calendar"></calendar>
            </div>

            <div class="right-col w-full lg:w-7/12 xl:w-6/12 lg:ml-4 pb-12 lg:pb-0 mt-8 md:mt-0">
                <passenger_details :hidden_initially=false @calendar_update="onCalendarUpdate" ref="passenger_details"></passenger_details>
                <pickup_point :trip_index=trip_index ref="pickup_points"></pickup_point>
                <sales_group :trip_index=trip_index :tour_id=tour_id  :year="year" :month="month" @selectedTour="onSelectedTour" ref="sales_group"></sales_group>
                <basket :trip_index=trip_index @Add_To_Cart="Add_To_Cart"></basket>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="./tour_details.ts"></script>