import { defineComponent } from 'vue';

import { Booking } from "../../services/booking"

import header_menu from "../header/menu.vue"
import footer_item from "../base/common/footer.vue"
import hero from "../header/hero.vue"

import basket from "../base/common/basket.vue"
import basket_minimal from "../base/common/basket_minimal.vue"
import basket_header from "../base/common/header.vue"

import booking_notes from "../base/4_checkout/booking_notes.vue"
import passengers from "../base/4_checkout/passengers.vue"
import trip_summary from "../base/4_checkout/summary.vue"
import { Web_Engine } from '../../services/web_engine';

import { response_booking } from "../../objects/v1_3/booking/search/response_booking";
import { response_booking_trip } from "../../objects/v1_3/booking/search/response_booking_trip";
import { response_booking_trip_passenger } from "../../objects/v1_3/booking/search/response_booking_trip_passenger";
import { currency } from "../../objects/v1_2/Currency/currency";

let Book: Booking = new Booking();
let web_engine: Web_Engine = new Web_Engine();
const isValid : boolean = false;

export default defineComponent({
    components: {
        booking_notes,
        footer_item,
        header_menu,
        basket,
        basket_minimal,
        basket_header,
        trip_summary,
        passengers,
        hero
    },
    data() {
        return {
            isValid,
            timedOut: false,
            user_currency: new currency as currency
            
        }
    },
    methods: {
        isAgent_Login(): boolean {
            return this.$store.getters.get_Agent_Login?.valid_login == true;
        },
        async initView() {
            //if(this.$store.getters.get_Booking?.group_booking_id == undefined || this.$store.getters.get_Booking?.group_booking_id == 0 || this.$store.getters.get_Booking?.group_booking_id == null) {

                //see if we have a valid bookingid param (payment link)
                let booking_id : string = this.getPaymentBookingId() ?? "";
                let session_id : string = this.getPaymentSessionId() ?? "";
                if (await this.has_Booking(booking_id, session_id)) {
                    this.isValid = true;
                };
            //}
            return isValid;
        },
        async has_Booking(group_booking_id: string, session_id: string) {
            let booking_details : response_booking = await Book.Get_Booking_Session(this.$store, group_booking_id, session_id).then((response) => {
                return response;
            });

            if (booking_details != null) {
                var firstTripCurrency : string = booking_details.trips[0].iso_currency_code ?? "GBP"; //this.$store.getters.get_Booking.trips[0];
                this.user_currency.currency_info = await web_engine.Get_Currency_By_Country_Code(firstTripCurrency);
                this.$store.commit('Initialise_Currency', { Currency_Details:  this.user_currency });
                return true
            };
            return false;
        },        
        getHeroImage(): string {
            return String(import.meta.env.VITE_DEFAULT_HERO_IMAGE);
        },
        home_Click() {
            this.$router.push('/');
        },
        getPaymentBookingId() {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('bid');
        },
        getPaymentSessionId() {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get('sid');
        },
       async startTimeOut() {
            await new Promise(resolve => setTimeout(resolve, 30000));
            this.timedOut = true
        },
        getContactInfo(): string {
            if (import.meta.env.VITE_DEFAULT_CONTACT_LOGIN_TEXT != undefined) {
                return String(import.meta.env.VITE_DEFAULT_CONTACT_LOGIN_TEXT);
            }
            else {
                return String(import.meta.env.VITE_DEFAULT_CONTACT_TEXT);
            }
        }
    },
    mounted() {
        this.initView();
        this.startTimeOut();
    }
})