﻿<template>

    <div class="flex-1 filter-tags">
        <ul>
            <p v-if="getFilterMessage(getTags()) != ''" class="filter-tags__title">{{getFilterMessage(getTags())}}</p>
            <li v-for="tag in getTags()">
                <a v-if="!tag_Selected(tag)" :active="tag_Selected(tag)" @click="toggleTag(tag)">{{tag.name}}</a>
                <a v-else style="color: rgb(219,26,33); border-color: rgb(219,26,33)" class="border-2  border-black"  @click="toggleTag(tag)">{{tag.name}}</a>
            </li>
        </ul>
    </div>

</template>

<script lang="ts" src="./filter_buttons.ts"></script>