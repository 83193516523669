﻿import { defineComponent } from 'vue';

import { Booking } from "../../../services/booking";
import { Currency_Service } from "../../../../system/services/currency";
import { Date_Service } from "../../../../system/services/date_service";

import { booking_info } from "../../../objects/v1_2/Booking_Details/booking_info";
import { trip } from '../../../objects/v1_2/Booking/Trip';

const Book: Booking = new Booking();
const Currency_Services: Currency_Service = new Currency_Service();
const Date_Services: Date_Service = new Date_Service();

export default defineComponent({
    name: "trip_summary",
    props: {
    },
    data() {
        return {
            hasAddOns: false
        }
    },
    methods: {
        get_Booking() {
            return this.$store.getters.get_Booking;
        },
        get_Basket() {
            return this.$store.getters.get_Basket;
        },
        get_Full_Total(): string {
            return Currency_Services.Format_Currency(Book.get_full_total_price(this.$store.getters.get_Booking, this.$store), this.$store.getters.get_Currency);
        },
        get_Discount(): string {
            return "- " + Currency_Services.Format_Currency(Book.get_discount(this.$store.getters.get_Booking), this.$store.getters.get_Currency);
        },
        is_Discounted(): boolean {
            return Book.get_discount(this.$store.getters.get_Booking) != 0;
        },
        get_Discount_Percentage(): string {
            if(this.isPaymentLink())
            {
                return "";
            }
            if(Book.get_Discount_Percentage(this.$store.getters.get_Booking, this.$store) != 0)
            {
                return "("+Book.get_Discount_Percentage(this.$store.getters.get_Booking, this.$store) + "%)";
            }
            else
            {
                let x = Book.get_discount(this.$store.getters.get_Booking);
                let y = Book.get_total_price(this.$store.getters.get_Booking, this.$store) + Book.get_discount(this.$store.getters.get_Booking)
                return "(" + parseFloat(((x / y) * 100)).toFixed(0) + "%)";
            }
        },
        isPaymentLink(): boolean {
            return window.location.href.includes("paylink");
        },
        get_Price(price: number): string {
            return Currency_Services.Format_Currency(price, this.$store.getters.get_Currency);
        },
        get_Departure_Date(departure_date: string): string {
            return Date_Services.string_full_date(departure_date);
        },
        getPassenger(passenger_identifier: string): string {
            return Book.get_Passenger(passenger_identifier, this.$store.getters.get_Booking);
        },
        getRoom_Type(trip_passenger_identifier: string, trip: booking_info): string {
            return Book.getRoom_Type(trip_passenger_identifier, trip);
        },
        get_AddOnText(): string {
            let Add_Ons: number = Book.getTotal_Add_Ons(this.$store.getters.get_Basket);
            this.hasAddOns = Add_Ons > 0;

            if (Add_Ons > 0) {
                return "Optional Add-on (x" + Add_Ons + ")";
            }

            return "";
        },
        get_AddOn_Total(): string {
            return Currency_Services.Format_Currency(Book.get_add_ons(this.$store), this.$store.getters.get_Currency);
        },
        get_Trip_Total(): string {
            return Currency_Services.Format_Currency(Book.get_total_price(this.$store.getters.get_Booking, this.$store) + Book.get_discount(this.$store.getters.get_Booking), this.$store.getters.get_Currency);
        },
        get_Total(): string {
            return Currency_Services.Format_Currency(Book.get_total_price(this.$store.getters.get_Booking, this.$store) + Book.get_add_ons(this.$store), this.$store.getters.get_Currency);
        }
    },
    mounted() {
    }
})