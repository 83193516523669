﻿import { createWebHistory, createRouter } from "vue-router"
declare global { interface Window { dataLayer: any; } }

import NotFound from "../pages/NotFound.vue"

import Search from "../../tms/booking_engine/components/consumer/1_search.vue"
import Cart from "../../tms/booking_engine/components/consumer/3_cart.vue"
import Payment from "../../tms/booking_engine/components/consumer/4_check_out.vue"
import Credit_Card from "../../tms/booking_engine/components/consumer/4a_payment.vue"
import Pay_Link from "../../tms/booking_engine/components/consumer/4b_pay_link.vue"
import Confirmation from "../../tms/booking_engine/components/consumer/5_confirmation.vue"
import failed_payment from "../../tms/booking_engine/components/consumer/5b_failed.vue"

import store from "../../tms/booking_engine/store/index";

import UmbracoTourCalendar from "../../tms/booking_engine/components/consumer/2b_UmbracoTourCalendar.vue"

function getTouridFromUmbracoTourname(tourname : string) {
    //get this from a generated list/service
    switch(tourname) {
        case "loch-ness-explorer": {
            return 'HXF';
            break;
        }
        case "whisky-waterfalls": {
            return 'H68';
            break;
        }
        default: {
            return '';
            break;
        }
    }
}

function getPropsWithTouridFromUmbracoTourname (route) {
    return {
        tour_id: getTouridFromUmbracoTourname(route.params.tourname)
    }
}

function getParamMetaTitle() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('open');
    const returnString = "Trips";
    if (myParam) {
        return returnString + " | " + myParam;
    }
    return returnString;
}

function getParamMetaDescription() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('open');
    const returnString = "" ;
    if (myParam) {
        returnString + "Calendar for tour: " + myParam;
    }
    else
    {
        returnString + "View our tours across scotland";
    }
    return returnString;
}

const history = createWebHistory();
const routes = [
    { path: "/", component: Search, name: "Search Screen", meta: { title: getParamMetaTitle(), description: getParamMetaDescription()}},
    { path: "/cart", component: Cart, name: "Booking Cart", meta: { title: "Cart" } },
    { path: "/payment", component: Payment, name: "Passenger Details", meta: { title: "Passenger Details" } },
    { path: "/paylink", component: Pay_Link, name: "Payment Link", meta: { title: "Payment Link" } },
    { path: "/creditcard", component: Credit_Card, name: "Payment", meta: { title: "Payment" } },
    { path: "/confirmation", component: Confirmation, name: "Confirmation", meta: { title: "Confirmation" } },
    { path: "/failed_payment?:reason", component: failed_payment, name: 'failed_payment', meta: { title: " Payment failed" } },

    /* Umbraco HEX tour calendar */
    { path: "/1-day-tours/:tourname/", component: UmbracoTourCalendar, props: getPropsWithTouridFromUmbracoTourname },

    { path: "/:pathMatch(.*)*", component: NotFound, name: "Page Not Found", meta: { title: "Page Not Found" } }
]

const router = createRouter({
    history, routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        }

        return { };
    }
});

router.beforeEach((to, from, next) => {    
    window.document.title = String(to.meta.title) || "Unset Title";
    next();
});

export default router;