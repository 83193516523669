import { product_search_departure } from "./product_search_departure";
import { product_detail_operational_information_duration } from "../product/product_detail_operational_information_duration";
import { product_detail_marketing_information_media_asset } from "../product/product_detail_marketing_information_media_asset";
import { product_price } from "../product/product_price";
import { product_sales_group } from "../product/product_sales_group";
import { product_detail_seller_information_month } from "../product/product_detail_seller_information_month";
import { product_detail_operational_information_location } from "../product/product_detail_operational_information_location";

export class product_search_product {
    public tour_id: string = "";
    public name: string = "";
    public name_short: string = "";
    public duration: product_detail_operational_information_duration = new product_detail_operational_information_duration();
    public description_headline: string = "";
    public description_short: string = "";
    public description: string = "";
    public web_url: string = "";
    public media_asset: product_detail_marketing_information_media_asset = new product_detail_marketing_information_media_asset();
    public departures: product_search_departure[] = [];
    public start_location: product_detail_operational_information_location = new product_detail_operational_information_location();
    public sales_group: product_sales_group = new product_sales_group();
    public months: product_detail_seller_information_month[] = [];
    public prices: product_price[] = [];
    public mapping_codes: string[] = [];
    public isrunning_on_departure_date: boolean = false;
    public post_tour: boolean = false;
    public post_tour_offset: number = 0;
    public pre_tour: boolean = false;
    public pre_tour_offset: number = 0;
}