<template>

    <header class="header">
        <header_menu></header_menu>
    </header>

    <main v-if="isValid" class="main">

        <div class="grid grid-cols-1 md:grid-cols-2">
            <h1 class="ml-1">Payment Link</h1>
            <basket_header :position=3></basket_header>
        </div>

        <p class="ml-1 mt-0 text-grey-default">Please check all passenger details and choose your payment option.</p>

        <div class="cart-content flex flex-col md:flex-row">

            <div class="payment cart-content flex flex-col md:flex-row">

                <div class="cart-items w-full md:w-7/12 lg:w-8/12 pr-0 md:pr-4 xl:pr-8 mb-8">
                    <basket></basket>
                    <booking_notes></booking_notes>
                </div>

                <trip_summary></trip_summary>

            </div>
        </div>

    </main>
    <main v-else class="main">
        <div class="grid grid-cols-1 md:grid-cols-2">
            <h1 class="ml-1">Payment Link</h1>
            <basket_header :position=3></basket_header>
        </div>
        <div v-if="!timedOut">
            <p class="inline-block ml-1 mt-0 mr-2 text-grey-default">Loading booking</p>
            <svg class="animate-spin h-5 w-5 mr-3 inline text-grey-default" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512" stroke="currentColor">
                <path fill="currentColor" d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z" class=""></path>
            </svg>
        </div>
        <div class="text-center" v-else>
            <h2 class="tourTitle">Booking refrence not found</h2>
            <h4>Whoops! Sorry, we haven't been able to find your booking</h4>
            <h5 class="text-grey-darker">{{getContactInfo()}}</h5>
        </div>
    </main>

    <footer_item></footer_item>

</template>

<script lang="ts" src="./4b_pay_link.ts"></script>