﻿import header_menu from "../header/menu.vue"
import hero from "../header/hero.vue"

import searchfilter from "../base/1_search/searchfilter.vue"
import filter_buttons from "../base/1_search/tour_list/filter_buttons.vue";
import tour_card from "../base/1_search/tour_card.vue"
import tour_details from "../base/2_tour_details/tour_details.vue"

import { Booking } from "../../services/booking";
import { Currency_Service } from "../../../system/services/currency";
import { Product } from '../../services/product';
import { Web_Engine } from "../../services/web_engine";

import { product_search } from '../../objects/v1_3/product/search/product_search';

import { product_detail } from '../../objects/v1_3/product/product/product_detail';
import sales_group from "../base/2_tour_details/sales_group.vue"
import calendar from "../base/2_tour_details/calendar.vue"
import routes from '../../../../../front-end-web/src/routes';


const Book = new Booking;
const Currency_Services = new Currency_Service;
const Prod = new Product;
const Web_Engines = new Web_Engine;

export default {
    components: {
        header_menu,
        hero,
        searchfilter,
        filter_buttons,
        tour_card,
        tour_details,
        sales_group,
        calendar
    },
    props: {
        tour_id: ''
    },
    data() {
        return {
            product: new product_detail(),
            tour_ids: [],
            selected_tour_id: "",
            year: 0,
            month: 0
        }
    },
    methods: {
        onBookTour(tour_id: string)
        {
            if (tour_id != this.tour_id) {
                this.tour_id = tour_id;
            }

            this.$refs.tour_details.download_product(tour_id);
            this.$store.commit('Initialise_Basket', { Currency: this.$store.getters.get_Currency?.currency_info?.iso_currency_code, Tour_ID: tour_id });

            this.isHidden = false;
        },

    },
    mounted() {
        //this.tour_id = this.$route.params.tour_id; //'HXF';// this.getTourCodeFromUmbracoTourname(this.$route.params.tourname );
//        this.download_product(this.tour_id);
        this.onBookTour(this.tour_id);

    }
}