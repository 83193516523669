import { defineComponent } from 'vue';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

export default defineComponent({
    name: 'App',
    data() {
        return {
            isActive: false
        }
    },
    methods: {
        setup_startup() {
            this.setup_script(String(import.meta.env.VITE_PAYMENT_TTC_LIBRARY));

            const appInsights = new ApplicationInsights({
                config: {
                    connectionString: String(import.meta.env.VITE_APP_INSIGHTS),
                    enableAutoRouteTracking: true
                }
            });

            appInsights.loadAppInsights();
            appInsights.trackPageView();
        },
        setup_script(script: string) {
            let externalScript = document.createElement('script');
            externalScript.setAttribute('src', script);
            document.head.appendChild(externalScript);

            document.body.classList.add(String(import.meta.env.VITE_DEFAULT_CLASS));
        },
        SetFavicon() {
            var link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.head.appendChild(link);
            }
            link.href = String(import.meta.env.VITE_DEFAULT_TAB_ICON);
        }
    },
    components: {
    },
    mounted() {
        this.setup_startup();
        this.SetFavicon();
    }
})